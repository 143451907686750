import React from "react"
import Header from "./../components/header"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default () => {
  return (
    <Layout>
      <Header headerText="404 - oh no" />
      <Link to="/">Go back home</Link>
    </Layout>
  )
}
